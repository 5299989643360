<template>
  <div
    class="r-logo"
    :style="getStyle()"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: null
    }
  },
  methods: {
    getStyle() {
      return this.size ? { width: `${this.size}px` } : {}
    }
  }
}
</script>

<style lang="scss">
.r-logo {
  background-image: url("../../assets/images/logos/nipigas-logo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
  height: 100%;
  max-height: 100%;
  width: auto;
  margin: 0 auto;
}
</style>
