<template>
  <div class="map-tools">
    <ul class="map-tools__list">
      <li
        v-for="tool in filteredTools"
        :key="tool.id"
        :class="`map-tools__item ${tool.id}`"
        @click="clickHandler(tool.id)"
      >
        <template v-if="tool.id === '3d'">
          <r-icon
            :name="is3d ? '3D' : tool.icon"
            :size="20"
          />
        </template>
        <template v-else-if="tool.id === 'reset'">
          <r-icon
            :style="`transform: rotateZ(${mapBearing}deg)`"
            :name="tool.icon"
            :size="20"
          />
        </template>
        <template v-else>
          <r-icon
            :name="tool.icon"
            :size="20"
          />
        </template>

        <map-baselayers
          v-if="tool.id === 'baselayers'"
          :baselayer-id="baselayerId"
          @select="clickHandler(tool.id, $event)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import MapBaselayers from './helpers/r-map-baselayers'
import { dataURLtoBlob } from '@/utils'

const allTools = [
  { id: 'baselayers', icon: 'folder-layer' },
  { id: '3d', icon: '2D' },
  { id: 'reset', icon: 'compass-north' },
  { id: 'screenshot', icon: 'image' }
]

export default {
  components: {
    MapBaselayers
  },
  props: {
    tools: {
      type: Array,
      default: () => ['3d', 'reset']
    },
    map: {
      type: Object,
      required: true
    },
    baselayerId: {
      type: Number,
      default: 5
    },
    is3d: {
      type: Boolean
    },
    mapBearing: {
      type: Number,
      default: 0
    },
    module: {
      type: String,
      default: 'map'
    }
  },
  computed: {
    filteredTools() {
      return allTools.filter(t => this.tools.includes(t.id))
    },
    imageData() {
      return {
        src: this.module === 'map' ? '/nipigas-logo.png' : '/odd-legend@2x.png',
        width: this.module === 'map' ? 120 : 1072,
        height: this.module === 'map' ? 114 : 80
      }
    }
  },
  methods: {
    clickHandler(id, payload) {
      switch (id) {
        case '3d': {
          const pitch = this.is3d ? 0 : 60
          this.map.easeTo({ pitch })
          this.$emit('change-prop', { field: 'is3d', value: !this.is3d })

          return
        }
        case 'reset': {
          this.map.easeTo({ pitch: 0, bearing: 0 })
          this.$emit('change-prop', { field: 'is3d', value: false })

          return
        }
        case 'baselayers': {
          if (!payload) return
          if (this.baselayerId === payload.id) return

          this.$emit('toggle-base-layer', payload)

          return
        }
        case 'screenshot': {
          this.exportMap()
        }
      }
    },
    addLogo() {
      return new Promise((resolve, reject) => {
        const logo = new Image()

        logo.src = this.imageData.src
        logo.onload = () => resolve(logo)
        logo.onerror = reject
      })
    },
    async addLegend() {
      const canvasContainer = this.map.getCanvasContainer()
      const { width, height } = this.map.getCanvas()
      const canvas = document.createElement('canvas')
      const canvasId = 'customCanvasLegend'
      const ctx = canvas.getContext('2d')

      const mapBbox = () => {
        const bounds = this.map.getBounds()
        const { _ne, _sw } = bounds

        return [
          [_sw.lng, _ne.lat],
          [_ne.lng, _ne.lat],
          [_ne.lng, _sw.lat],
          [_sw.lng, _sw.lat]
        ]
      }
      canvasContainer.insertAdjacentElement('afterbegin', canvas)

      canvas.width = width
      canvas.height = height
      canvas.style = 'position: absolute'
      canvas.id = canvasId

      const padding = 16

      const imageX = width - this.imageData.width - padding
      const imageY = height - this.imageData.height - padding

      ctx.drawImage(await this.addLogo(), imageX, imageY)

      this.removeLegend()

      this.map.addSource(canvasId, {
        type: 'canvas',
        canvas: canvasId,
        coordinates: mapBbox()
      })

      this.map.addLayer({
        id: 'canvas-layer',
        type: 'raster',
        source: canvasId
      })
    },
    removeLegend() {
      if (this.map.getLayer('canvas-layer')) {
        this.map.removeLayer('canvas-layer')
      }
      if (this.map.getSource('customCanvasLegend')) {
        this.map.removeSource('customCanvasLegend')
      }
    },
    exportMap() {
      this.addLegend()

      return new Promise(() => {
        setTimeout(() => {
          const base = this.map.getCanvas().toDataURL('image/png')
          const blob = dataURLtoBlob(base)
          const objectUrl = window.URL.createObjectURL(blob, {
            type: 'image/png'
          })

          const link = document.createElement('a')
          link.setAttribute('download', 'nipigas-map.png')
          link.setAttribute('href', objectUrl)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          this.removeLegend()
        }, 500)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map-tools {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: var(--border-radius);
  background-color: var(--modal_bg) !important;
  z-index: 1001;

  &__item {
    width: 36px;
    height: 36px;
    cursor: pointer;
    color: var(--text_primary) !important;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: var(--accent_hover);
    }

    &.baselayers {
      &:hover {
        .map-baselayers {
          display: block;
        }
      }
    }
  }
}
</style>
