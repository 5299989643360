import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// basic
import AppComponent from '@/views/app/app.vue'

// modules
import MapComponent from '@/views/app/map/map.vue'
import Modelling from '@/views/app/modelling/modelling'
import MModel from '@/views/app/modelling/models/model/model'

import MScenarioList from '@/views/app/modelling/models/model/scenarios/list'
import MScenarioEditor from '@/views/app/modelling/models/model/scenarios/editor'
import MScenario from '@/views/app/modelling/models/model/scenarios/scenario'

import BookComponent from '@/views/app/book/book.vue'

import DashboardComponent from '@/views/app/dashboard/dashboard.vue'

import MonitoringComponent from '@/views/app/monitoring/monitoring'
import MonitoringTmc from '@/views/app/monitoring-tmc/monitoring-tmc'

import EventEditorComponent from '@/views/app/event-editor/event-editor.vue'

import OddComponent from '@/views/app/odd/odd'
import OddRouter from '@/views/app/odd/odd-router'
import OddRouterView from '@/views/app/odd/odd-router-view'
import OddRouterCreate from '@/views/app/odd/odd-router-create'

import PublicTransportComponent from '@/views/app/public-transport/public-transport'
import PtViewComponent from '@/views/app/public-transport/panels/view'
import PtEditorComponent from '@/views/app/public-transport/panels/editor'
import PtRouterComponent from '@/views/app/public-transport/panels/router'

import RailwayControl from '@/views/app/railway-control/railway-control'

import Analytics from '@/views/app/analytics/analytics'
import Reports from '@/views/app/analytics/reports'

import RolePermission from '@/views/app/role-permission/role-permission'
import Calendar from '@/views/app/calendar'

import Pot1 from '@/views/app/pot-1/pot-1'
import Pot1Logistic from '@/views/app/pot-1/pot-1-logistic'
import Pot1Terminal from '@/views/app/pot-1-terminal'

import BddRestrictionsRegister from '@/views/app/bdd/bdd-restrictions-register'
import BddPreventiveMeasuers from '@/views/app/bdd/bdd-preventive-measures'
import BddViolationsRegister from '@/views/app/bdd/bdd-violations-register'
import bddPassRegister from '@/views/app/bdd/bdd-pass-register'
import BddApplications from '@/views/app/bdd/bdd-applications'
import BddActs from '@/views/app/bdd/bdd-acts'
import BddChecklists from '@/views/app/bdd-checklists'

import Journal from '@/views/app/journal/journal'

import accessDenied from '@/views/access/access-denied'
import Print from '@/views/app/print/print'

import KppDashboard from '@/views/app/kpp_dashboard/kpp_dashboard'
// ——————

const defaultPath = store.getters.getDefaultPath

Vue.use(VueRouter)

const routes = [
  {
    path: '/app',
    name: 'app',
    component: AppComponent,
    children: [
      {
        name: 'book',
        path: 'book',
        component: BookComponent
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        name: 'event-editor',
        path: 'event-editor',
        component: EventEditorComponent
      },
      {
        name: 'map',
        path: 'map',
        component: MapComponent
      },
      {
        name: 'role-permission',
        path: 'role-permission',
        component: RolePermission
      },
      {
        name: 'calendar',
        path: 'calendar',
        component: Calendar
      },
      {
        name: 'modelling',
        path: 'modelling',
        component: Modelling,
        redirect: '/app/modelling/main',
        children: [
          {
            name: 'm-model',
            path: 'main',
            component: MModel,
            redirect: '/app/modelling/main/list',
            children: [
              {
                name: 'm-scenarios',
                path: 'list',
                component: MScenarioList
              },
              {
                name: 'm-editor',
                path: 'editor',
                component: MScenarioEditor
              },
              {
                name: 'm-scenario',
                path: ':scenarioId',
                component: MScenario
              }
            ]
          }
        ]
      },
      {
        name: 'monitoring_ts',
        path: 'monitoring_ts',
        component: MonitoringComponent
      },
      {
        name: 'monitoring-tmc',
        path: 'monitoring-tmc',
        component: MonitoringTmc
      },
      {
        name: 'public-transport',
        path: 'public-transport',
        component: PublicTransportComponent,
        redirect: '/app/public-transport/view',
        children: [
          {
            name: 'pt-view',
            path: 'view',
            component: PtViewComponent
          },
          {
            name: 'pt-router',
            path: 'router',
            component: PtRouterComponent
          },
          {
            name: 'pt-editor',
            path: 'editor/:mode',
            component: PtEditorComponent
          }
        ]
      },
      {
        name: 'odd',
        path: 'odd',
        component: OddComponent,
        redirect: '/app/odd/objects',
        children: [
          {
            name: 'odd-objects',
            path: 'objects'
          },
          {
            name: 'odd-create',
            path: 'create'
          },
          {
            name: 'odd-router',
            path: 'router',
            component: OddRouter,
            children: [
              {
                name: 'odd-router-view',
                path: 'view',
                component: OddRouterView
              },
              {
                name: 'odd-router-create',
                path: 'create',
                component: OddRouterCreate
              }
            ]
          }
        ]
      },
      {
        name: 'railway-control',
        path: 'railway-control',
        component: RailwayControl
      },
      {
        name: 'unload',
        path: 'unload',
        component: () => import('@/views/app/unload')
      },
      {
        name: 'analytics',
        path: 'analytics',
        component: Analytics
      },
      {
        name: 'kpp_dashboard',
        path: 'kpp_dashboard',
        component: KppDashboard
      },
      {
        name: 'reports',
        path: 'reports',
        component: Reports
      },
      {
        name: 'bdd-applications',
        path: 'bdd/applications',
        component: BddApplications
      },
      {
        name: 'restrictions-register',
        path: 'bdd/restrictions-register',
        component: BddRestrictionsRegister
      },
      {
        name: 'preventive-measures',
        path: 'bdd/preventive-measures',
        component: BddPreventiveMeasuers
      },
      {
        name: 'pass-register',
        path: 'bdd/pass-register',
        component: bddPassRegister
      },
      {
        name: 'violations-register',
        path: 'bdd/violations-register',
        component: BddViolationsRegister
      },
      {
        name: 'acts',
        path: 'bdd/acts',
        component: BddActs
      },
      {
        name: 'pot-1-dispatcher',
        path: 'pot-1-dispatcher',
        component: Pot1
      },
      {
        name: 'pot-1-logistic',
        path: 'pot-1-logistic',
        component: Pot1Logistic
      },
      {
        name: 'journal',
        path: 'journal',
        component: Journal
      }
    ]
  },
  {
    name: 'access-denied',
    path: '/access-denied',
    component: accessDenied
  },
  {
    name: 'pot-1-terminal',
    path: '/pot-1-terminal',
    component: Pot1Terminal
  },
  {
    name: 'print',
    path: '/print',
    component: Print
  },
  {
    name: 'bdd-checklists',
    path: '/bdd-checklists',
    component: BddChecklists
  },
  {
    name: 'all-routes',
    path: '*',
    redirect: defaultPath
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
