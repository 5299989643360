export const filters = [
  {
    id: 'event_type',
    source: 'type',
    type: 'checkbox-filter',
    title: 'Тип события',
    prop: [
      { id: 'cross_geozone_in', name: 'Вход в геозону' },
      { id: 'cross_geozone_out', name: 'Выход из геозоны' },
      { id: 'deviation_from_route', name: 'Отклонение от маршрута' },
      { id: 'return_to_route', name: 'Выход на маршрут' },
      { id: 'over_speed', name: 'Превышение допустимой скорости' },
      { id: 'geozone_over_speed', name: 'Превышение скорости в геозоне' },
      { id: 'move', name: 'Движение' },
      { id: 'stop', name: 'Остановка' },
      { id: 'status_changed', name: 'Потеря связи с объектом' }
    ],
    active: false
  }
]
