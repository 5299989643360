export const tabsList = [
  {
    id: 1,
    value: 'included',
    title: 'Объекты фильтрации'
  },
  {
    id: 2,
    value: 'excluded',
    title: 'Не попавшие в фильтр'
  }
]

export const attrConfigs = {
  'f09e59fb-ee58-482c-8b0f-9ac520cf2099': {
    reg_number: 'ГРЗ',
    contractor: 'Подрядчик',
    geozones: 'Геозоны',
    vehicle_type: 'Вид ТС',
    categories: 'Категории',
    agpz: 'АГПЗ',
    aghk: 'АГХК',
    checkpoint: 'КПП',
    sender: {
      deepReflection: true,
      name: 'Поставщик',
      value: 'cargoes.sender',
      key: 'name',
      reflection: {
        source_id: 'ef021b44-a650-478e-a25f-ace1e9b545ca'
      }
    },
    mols: {
      deepReflection: true,
      name: 'МОЛ',
      value: 'cargoes.mols',
      key: 'fio',
      reflection: {
        source_id: '495a752e-32b4-4e32-af05-858716ee39ae'
      }
    }
  },
  'd1709d93-ca52-4453-a83b-ff7ab253a464': {
    packing_list_no: '№ Упаковочного',
    warehouses: {
      deepReflection: true,
      name: 'Склад/Площадка',
      value: 'vehicles.warehouse',
      key: 'name',
      reflection: {
        source_id: '30cd86ef-4c58-44ee-ab31-6f34f1c327b2'
      }
    },
    contractor: 'Подрядчик',
    project: 'Проект',
    subproject: 'Подпроект',
    subsubproject: 'Подподпроект',
    receiving_date_fact: 'Дата доставки, Факт',
    mols: 'МОЛ'
  }
}

export const reflectionKeys = {
  default: 'name',
  vehicles: 'reg_number',
  mols: 'fio'
}

export const projectFilter = {
  id: 'projects',
  value: 'projects',
  label: 'Проекты',
  children: [
    {
      id: 'all',
      treeId: 'all',
      label: 'Все',
      name: 'all'
    },
    {
      id: 'agpz',
      treeId: 'agpz',
      label: 'АГПЗ',
      name: 'АГПЗ'
    },
    {
      id: 'aghk',
      treeId: 'aghk',
      label: 'АГХК',
      name: 'АГХК'
    }
  ]
}

export const receivingDateFilter = {
  id: 'receiving_date_fact',
  value: 'receiving_date_fact',
  label: 'Дата доставки, Факт',
  children: [
    {
      id: 'true',
      treeId: 'true',
      label: 'Доставлен',
      name: 'Доставлен'
    },
    {
      id: 'false',
      treeId: 'false',
      label: 'Не доставлен',
      name: 'Не доставлен'
    }
  ]
}

export const columnWidth = {
  packing_list_no: '360px',
  contractor: '190px',
  receiving_date_fact: '160px',
  vehicle_type: '190px'
}
