import vue from 'vue'

export const state = {
  config: {
    city: '',
    timeZone: '',
    layout: {},
    map: {
      center: {},
      maxBounds: [],
      zoom: 10
    }
  },
  modalWindow: {
    layer_id: ''
  },
  objectCards: [],
  layersData: {
    map: {},
    widget: {}
  },
  mapPlugItems: {},
  completedRequests: [],
  badRequests: [],
  events: {
    layer_id: '',
    stats_id: '',
    classes: {},
    event_editor_link: ''
  },
  disabledLayers: ['orto-agpz', 'orto-aghk'],
  layout: {
    isShow: {
      left: true,
      bottom: true
    }
  },
  trackLayer: {
    isShow: false,
    layer_id: '',
    data: []
  },
  cardTrack: null,
  matrix: {
    enabled: false,
    hover: null,
    active: null,
    min: 0,
    max: 0
  },
  activeSC: null,
  flyTo: {},
  toggleVehicles: true,
  satellite: false
}

const mutations = {
  SET_SI_CENTER_FIELD(state, { field, value }) {
    state[field] = value
  },
  SI_CENTER_TOGGLE_ITEM(state, [field, value]) {
    const index = state[field]?.findIndex(f => f.id === value.id)

    if (index > -1) {
      state[field].splice(index, 1)
    } else {
      state[field].push(value)
    }
  },
  TOGGLE_SI_CENTER_LAYOUT(state, block) {
    vue.set(state.layout.isShow, block, !state.layout.isShow[block])
  },
  TOGGLE_SI_CENTER_VEHICLES(state, val) {
    vue.set(state, 'toggleVehicles', val)
  },
  SET_SI_CENTER_CONFIG(state, config) {
    vue.set(state, 'config', config)
  },
  SET_SI_CENTER_MODAL_WINDOW(state, { layer_id }) {
    vue.set(state.modalWindow, 'layer_id', layer_id)
  },
  SET_SI_CENTER_OBJECT_CARD(state, { layer_id, id, data }) {
    vue.set(state.objectCard, 'layer_id', layer_id)
    vue.set(state.objectCard, 'id', id)
    vue.set(state.objectCard, 'data', data)
  },
  ADD_SI_CENTER_LAYER_DATA(state, { layer_id, data, comp }) {
    vue.set(state.layersData[comp], layer_id, data)
  },
  UPDATE_SI_CENTER_LAYER_DATA(state, { layer_id, data, comp }) {
    vue.set(state.layersData[comp], layer_id, data)
  },
  ADD_SI_CENTER_COMPLETED_REQUEST(state, layer_id) {
    if (state.completedRequests.indexOf(layer_id) === -1) { state.completedRequests.push(layer_id) }
  },
  ADD_SI_CENTER_BAD_REQUEST(state, layer_id) {
    if (state.badRequests.indexOf(layer_id) === -1) { state.badRequests.push(layer_id) }
  },
  REMOVE_SI_CENTER_BAD_REQUEST(state, layer_id) {
    const index = state.badRequests.indexOf(layer_id)
    if (index > -1) state.badRequests.splice(index, 1)
  },
  TOGGLE_SI_CENTER_ACTIVE_LAYERS(state, value) {
    const index = state.disabledLayers.indexOf(value)

    if (index === -1) state.disabledLayers.push(value)
    else state.disabledLayers.splice(index, 1)
  },
  SET_SI_CENTER_EVENTS_INFO(
    state,
    { layer_id, stats_id, classes, event_editor_link, url_id }
  ) {
    vue.set(state.events, 'layer_id', layer_id)
    vue.set(state.events, 'stats_id', stats_id)
    vue.set(state.events, 'url_id', url_id)
    vue.set(state.events, 'classes', classes)
    vue.set(state.events, 'event_editor_link', event_editor_link)
  },
  SET_SI_CENTER_TRACK_LAYER(state, { layer_id, data }) {
    vue.set(state.trackLayer, 'layer_id', layer_id)
    vue.set(state.trackLayer, 'data', data)
  },
  SET_SI_CENTER_TRACK_LAYER_SHOW(state, value) {
    vue.set(state.trackLayer, 'isShow', value)
  },
  SET_SI_CENTER_MATRIX_PROP(state, { field, value }) {
    vue.set(state.matrix, field, value)
  },
  SET_SI_CENTER_ACTIVE_SC(state, module) {
    vue.set(state, 'activeSC', module)
  },
  SET_SI_CENTER_FLY_TO(state, { item }) {
    vue.set(state, 'flyTo', item)
  },
  CLEAR_SI_CENTER_FLY_TO(state) {
    vue.set(state, 'flyTo', null)
  },
  ADD_SI_CENTER_MAP_PLUG_ITEM(state, layer_id) {
    vue.set(state.mapPlugItems, layer_id, true)
  }
}

export default { state, mutations }
