<template>
  <div class="login-loader">
    <div class="login-loader__centered">
      <div class="login-loader__logo" />
      <div class="login-loader__text">
        <span class="el-icon-loading" />
        {{ $t(`auth.loader_text`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.login-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10000;
  color: var(--white_white) !important;
  background-color: rgba(0, 0, 0, 0.8);

  .el-icon-loading {
    font-size: 16px;
    margin-right: 5px;
    color: var(--white_white) !important;
  }

   &__logo {
    width: 120px;
    height: 114px;
    background-image: url('../../assets/images/logos/nipigas-logo.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
    margin: auto;
  }

  &__centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    font-size: 16px;
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text_secondary) !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "auth.loader_text": "Загрузка системы..."
  },
  "en": {
    "auth.loader_text": "System loading..."
  }
}
</i18n>
