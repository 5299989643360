<template>
  <div class="sc-object-card__video-archive">
    <div class="sc-object-card__video-archive-datepickers">
      <div class="sc-object-card__video-archive-datepickers__item">
        <r-text>
          Начало
        </r-text>
        <el-date-picker
          v-model="dateFrom"
          class="r-date-picker"
          format="dd.MM.yyyy HH:mm"
          type="datetime"
          align="right"
          placeholder="Выберите дату и время"
          :clearable="false"
          :picker-options="fromPickerOptions"
        />
      </div>
      <div class="sc-object-card__video-archive-datepickers__item">
        <r-text>
          Окончание
        </r-text>
        <el-date-picker
          v-model="dateTo"
          class="r-date-picker"
          format="dd.MM.yyyy HH:mm"
          type="datetime"
          align="right"
          placeholder="Выберите дату и время"
          :clearable="false"
          :picker-options="toPickerOptions"
        />
      </div>
    </div>
    <r-button
      type="primary"
      mini
      :loading="isLoading"
      :disabled="isDisabled"
      @click="loadVideo"
    >
      Загрузить
    </r-button>
    <div
      v-if="filteredVideoArchiveList.length"
      class="sc-object-card__video-archive-wrapper"
    >
      <r-text>
        Список видеоархива
      </r-text>
      <video-archive-item
        v-for="videoItem in filteredVideoArchiveList"
        :key="videoItem.id"
        :info="videoItem"
      />
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { getDatetime } from '@/components/situation-center/configs'
import videoArchiveItem from './renderer/video-archive-item'

export default {
  components: {
    videoArchiveItem
  },
  props: {
    id: {
      type: String,
      required: true
    },
    videoArchive: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timezone: '+9',
      refreshData: null,
      isLoading: false,
      dateTo: null,
      dateFrom: null,
      fromPickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: this.disableFrom
      },
      toPickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: this.disableTo
      },
      videoArchiveList: null
      // teamplateVideoList
    }
  },
  computed: {
    requestUrl() {
      return `${this.videoArchive?.url}?cam=${this.id}`
    },
    filteredVideoArchiveList() {
      return this.videoArchiveList || []
    },
    isDisabled() {
      return !this.dateFrom || !this.dateTo
    }
  },
  created() {
    this.setDateFrom()
    this.loadVideoArchive()
    this.refreshData = setInterval(this.loadVideoArchive, 30000)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    async loadVideoArchive() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: this.requestUrl
        })
        this.videoArchiveList = data
      } catch (e) {
        console.log(e)
      }
    },
    async loadVideo() {
      console.log('loadVideo')
      if (!this.id) {
        const message =
          'Ошибка при создании запроса на сервер, обратитесь к администратору'
        this.$notify(notifyFactory('error', 'Ошибка', message))
        return
      }
      try {
        this.isLoading = true
        const dateFrom = this.$rDate.format(
          this.dateFrom,
          'YYYY-MM-DD-T-HH-mm-ss'
        )
        const dateTo = this.$rDate.format(this.dateTo, 'YYYY-MM-DD-T-HH-mm-ss')
        const data = {
          camera: this.id,
          from: dateFrom.replaceAll('-', ''),
          to: dateTo.replaceAll('-', '')
        }
        await this.$store.dispatch('POST_REQUEST', {
          url: this.requestUrl,
          data
        })
        const message = 'Запрос на выгрузку видеоархива успешно отправлен'
        this.$notify(notifyFactory('success', 'Уведомление', message))
        this.loadVideoArchive()
        this.isLoading = false
      } catch (e) {
        console.log(e)
        const message = 'Не удалось отправить запрос'
        this.$notify(notifyFactory('error', 'Ошибка', message))
        this.isLoading = false
      }
    },
    setDateFrom() {
      const datetime = getDatetime(this.timezone)
      this.dateTo = datetime
      this.dateFrom = this.dateTo - 60 * 60 * 1000
    },
    disableFrom(date) {
      if (!this.dateTo) {
        return date >= new Date()
      } else {
        const toDate = new Date(this.dateTo)

        return toDate >= new Date() ? date >= new Date() : date >= toDate
      }
    },
    disableTo(date) {
      const fromDate = new Date(this.dateFrom)
      const dateNow = new Date()

      return date <= fromDate - 24 * 60 * 60 * 1000 || date >= dateNow
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__video-archive {
  position: relative;

  > * {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &-datepickers {
    &__item {
      &:not(:first-child) {
        margin-top: 8px;
      }
      .r-text {
        margin-bottom: 4px;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    > * {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);

    &__controls {
      display: flex;

      > * {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
