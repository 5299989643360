export const testReportsList = [
  {
    id: 1,
    title: 'Форма № 1241-2А'
  },
  {
    id: 2,
    title: 'Отчёт о движении товаро-материальных ценностей'
  },
  {
    id: 3,
    title: 'Отчёт Нагрузка КПП неделя'
  },
  {
    id: 4,
    title: 'Отчёт Поток транспорта ПОТ-1'
  },
  {
    id: 5,
    title: 'Форма № 1241-2А'
  },
  {
    id: 6,
    title: 'Отчёт Свод ТМЦ неделя'
  },
  {
    id: 7,
    title: 'Отчёт Свод ТМЦ месяц'
  },
  {
    id: 8,
    title: 'Отчёт График аварийности ПАТ неделя'
  },
  {
    id: 9,
    title: 'Отчёт График аварийности ПАТ месяц'
  },
  {
    id: 10,
    title: 'Отчёт График аварийности ГАТ неделя'
  },
  {
    id: 11,
    title: 'Отчёт График аварийности ГАТ месяц'
  },
  {
    id: 12,
    title: 'Статус по проведенным техническим осмотрам транспортных средств, допущенных на подпроект P1 АГПЗ'
  },
  {
    id: 13,
    title: 'Статус по проведенным техническим осмотрам транспортных средств, допущенных на подпроект P2 АГПЗ'
  },
  {
    id: 14,
    title: 'Форма № 1241-2А'
  },
  {
    id: 15,
    title: 'Отчёт График аварийности ПАТ месяц'
  }
]
