export const modulesConfig = [
  {
    id: 1,
    name: 'gis',
    icon: 'ksodd',
    components: [
      { id: 1, name: 'Map', value: 'map', icon: 'ksodd-map' },
      { id: 2, name: 'Book', value: 'book', icon: 'ksodd-tables' },
      { id: 3, name: 'ODD', value: 'odd', icon: 's-center_editor' }
    ]
  },
  {
    id: 2,
    name: 'monitoring',
    icon: 'bus-hollow',
    components: [
      {
        id: 1,
        name: 'Internal Monitoring',
        value: 'internal-monitoring',
        icon: 'bus-hollow'
      },
      {
        id: 2,
        name: 'Railway Dispatcher',
        value: 'railway-dispatcher',
        icon: 'railway-control'
      },
      { id: 3, name: 'PT Registry', value: 'pt-registry', icon: 'bus-hollow' },
      {
        id: 4,
        name: 'External Monitoring',
        value: 'external-monitoring',
        icon: 'bus-hollow'
      }
    ]
  },
  {
    id: 3,
    name: 'analytics',
    icon: 'ksodd-graphs',
    components: [
      { id: 1, name: 'Analytics', value: 'analytics', icon: 'ksodd-graphs' },
      { id: 2, name: 'KppDashboard', value: 'kpp_dashboard', icon: 'ksodd-graphs' }
      // { id: 2, name: 'Reports', value: 'reports', icon: 'ksodd-graphs' }
    ]
  },
  {
    id: 4,
    name: 'modelling',
    icon: 'modelling',
    components: [
      { id: 1, name: 'Modelling', value: 'modelling', icon: 'modelling' }
    ]
  },
  {
    id: 5,
    name: 'situation-center',
    icon: 's-center',
    components: [
      {
        id: 1,
        name: 'Situation Center Display',
        value: 'situation-center-display',
        icon: 's-center'
      }
    ]
  },
  {
    id: 6,
    name: 'bdd',
    icon: 'bdd',
    components: [
      {
        id: 1,
        name: 'Vehicle Inspection Applications',
        value: 'via',
        icon: 'bdd'
      },
      { id: 2, name: 'Vehicle Inspection Sheets', value: 'vis', icon: 'bdd' },
      { id: 3, name: 'Register of closed areas', value: 'roca', icon: 'closed' },
      { id: 4, name: 'Register of preventive measures', value: 'ropm', icon: 'work' },
      { id: 5, name: 'Register of passes', value: 'rop', icon: 'kpp-gate' },
      { id: 6, name: 'Register of violations', value: 'rov', icon: 'bdd' },
      { id: 7, name: 'Acts register', value: 'acts', icon: 'bdd' }
    ]
  },
  {
    id: 7,
    name: 'pot-1',
    icon: 'pot-1',
    components: [
      {
        id: 1,
        name: 'POT-1 Dispatcher',
        value: 'pot-1-dispatcher',
        icon: 'pot-1'
      },
      {
        id: 2,
        name: 'POT-1 Warehouse',
        value: 'pot-1-logistic',
        icon: 'pot-1'
      },
      {
        id: 3,
        name: 'POT-1 Terminal',
        value: 'pot-1-terminal',
        icon: 'pot-1'
      },
      { id: 4, name: 'unload', value: 'unload' }
    ]
  },
  {
    id: 'system-components',
    name: 'system-components',
    icon: 'settings',
    components: [
      {
        id: 'layer-top-panel',
        name: 'Layers Top Panel',
        value: 'layer-top-panel',
        icon: 'list'
      },
      {
        id: 'journal',
        name: 'Journal',
        value: 'journal',
        icon: 'list'
      },
      {
        id: 'mail-sender',
        name: 'Mail Sender',
        value: 'mail-sender',
        icon: 'list'
      },
      {
        id: 'datasource-manager',
        name: 'DataSource Manager',
        value: 'datasource-manager',
        icon: 'list'
      },
      {
        id: 'pat_notifications',
        name: 'PAT notifications',
        value: 'pat_notifications',
        icon: 'public-notification'
      }
    ]
  },
  {
    id: 'calendar',
    name: 'calendar',
    icon: 'date-calendar',
    components: [
      { id: 1, name: 'Calendar', value: 'calendar' }
    ]
  }
]
