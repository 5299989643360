<template>
  <div
    v-loading="iconsLoading"
    class="odd-map"
  >
    <div id="odd-map" />

    <div class="odd-map__router">
      <odd-router-button v-if="$route.name === 'odd-objects' && !cardId" />
    </div>

    <div
      v-if="hasModule('Layers Top Panel') && mapReady"
      class="odd-map__layers"
    >
      <r-info-layers
        ref="infoLayers"
        :mapgl="mapgl"
        :locked-layers="['graph']"
        :enabled-layers="infoLayers"
        module="vehicle_inspection_sheets"
      />
    </div>

    <div class="odd-map__actions">
      <r-map-tools
        v-if="mapgl"
        :tools="['baselayers', '3d', 'reset', 'screenshot']"
        :map="mapgl"
        :map-bearing="mapBearing"
        :is3d="is3d"
        :baselayer-id="baselayerId"
        module="odd"
        @change-prop="changeProp"
        @toggle-base-layer="toggleBaselayer"
      />

      <odd-legend />
    </div>

    <r-map-loader v-if="loadingLayers.length > 0" />
    <router-loader v-if="makingRoutingRequest" />

    <map-popup-list :settings="popupListSettings" />
  </div>
</template>

<script>
import extent from 'turf-extent'
import { initMap } from './core'
import { loadIcons } from './helpers'
import OddLegend from './components/legend'
import OddRouterButton from './components/router-button'
import RouterLoader from './components/router-loader'
import MapPopupList from './components/map-popup-list'
import { getMapConfigs } from '@/utils'

export default {
  components: {
    OddLegend,
    OddRouterButton,
    RouterLoader,
    MapPopupList
  },
  data() {
    return {
      mapgl: null,
      draw: null,
      mapBearing: null,
      is3d: false,
      controllers: {},
      helpers: {},
      makingRoutingRequest: false,
      loading: false,
      mapReady: false,
      infoLayers: [
        'vehicles',
        'graph',
        'transportSituation',
        'geozones',
        'passingStatistic',
        'kppAghk',
        'kppAgpz',
        'abkAgpz',
        'overpassesAgpz',
        'shahmatka',
        'werehouse',
        'tilelayers'
      ],
      popupListSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      }
    }
  },
  computed: {
    showSings() {
      return this.$store.state.odd.showSings
    },
    showEvents() {
      return this.$store.state.odd.showEvents
    },
    loadingLayers() {
      return this.$store.state.odd.loadingLayers
    },
    baselayerId() {
      return (
        this.$store.state.mapConfigs?.baselayers?.vehicle_inspection_sheets || 5
      )
    },
    cardId() {
      return this.$store.state.odd.cardId
    },
    eventsFilter() {
      return this.$store.state.odd.eventsFilter || {}
    },
    activeRoutes() {
      return this.$store.state.odd.routerState.activeRoutes
    },
    iconsLoading() {
      return this.$store.state.odd.iconsLoading
    }
  },
  watch: {
    loading(v) {
      this.$emit('setLoading', v)
    },
    $route(val, prev) {
      const { editor, base, router } = this.controllers

      if (!editor || !base || !router) return

      switch (val.name) {
        case 'odd-objects': {
          if (prev.name === 'odd-create') {
            editor.disableEditor()
          } else {
            this.$store.commit('CLEAR_ODD_ROUTER_ACTIVE_ITEMS')
          }

          base.updateLayers()
          break
        }
        case 'odd-create': {
          editor.enableCreateMode()
          break
        }
        case 'odd-router-create': {
          router.enableRouter()
          break
        }

        case 'odd-router-view': {
          if (prev.name === 'odd-router-create') {
            router.disableRouter()
          }
          break
        }
      }
    },
    '$store.state.odd.cardId'(val, prev) {
      if (!val && this.$route.name !== 'odd-create') {
        this.controllers.editor.disableEditor()
      }

      this.mapgl.resize()
    },
    '$store.state.odd.featureToEdit'(val) {
      if (val) {
        this.controllers.editor.enableEditMode()
      }
    },
    '$store.state.odd.newCardId'(val, prev) {
      if (!val && this.$route.name === 'odd-create') {
        this.controllers.editor.removeCreatedObject()
      }
    },
    '$store.state.odd.editorState.mode'(value) {
      if (this.$route.name === 'odd-create') {
        this.controllers.editor.toggleMode(value)
      }
    },
    '$store.state.odd.editorState.editByRoute'(value) {
      if (value) {
        this.controllers.editor.toggleEditByRoute()
      }
    },
    '$store.state.odd.editorState.editByRouteGeom'(value) {
      if (value) {
        this.controllers.editor.updateEditMode(value)
      }
    },
    '$store.state.odd.editorState.featureToDelete'(id) {
      if (id) {
        this.controllers.editor.deleteFeature(id)
      }
    },
    '$store.state.odd.needToUpdate'(value) {
      if (value) {
        this.controllers.model.loadLists()
        this.controllers.base.updateLayers()
        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToUpdate',
          value: false
        })
      }
      this.mapgl.resize()
    },
    '$store.state.odd.flyToGeom': function(geom) {
      if (!geom) return

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 17
      })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    activeRoutes(routes) {
      this.controllers.router.updateRoutesLayer(routes)
    },
    eventsFilter: {
      handler() {
        this.updateEvents()
      },
      deep: true
    },
    showSings() {
      this.updateEvents()
    },
    showEvents() {
      this.updateEvents()
    }
  },
  mounted() {
    this.loading = true
    initMap(this)

    this.mapgl.once('load', () => {
      this.mapReady = true
    })
  },
  beforeDestroy() {
    this.$store.commit('SET_ODD_FIELD', {
      field: 'cardId',
      value: null
    })
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.saveMapParams()
  },
  methods: {
    async updateEvents() {
      try {
        this.$emit('setLoading', true)

        if (!this.showEvents && !this.showSings) {
          await this.controllers.base.removeBaseLayers()
        } else {
          await this.controllers.model.loadEvents()
          await this.controllers.base.addBaseLayers()
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$emit('setLoading', false)
      }
    },
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('oddMapCenter', JSON.stringify(center))
      localStorage.setItem('oddMapZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'vehicle_inspection_sheets',
        value: id
      })
      const { baselayer } = getMapConfigs(this, 'vehicle_inspection_sheets')

      this.mapgl.setStyle(baselayer)
      this.mapgl.once('style.load', async() => {
        await loadIcons(this, this.$store.state.odd.books.signIcons)
        await this.$refs.infoLayers.rerenderComponent()
        this.controllers.base.addBaseLayers()
        this.controllers.router.addRoutesLayer()
        this.controllers.router.updateRoutesLayer(this.activeRoutes)
      })
    },
    getObjectsLimit() {
      return 10000
    }
  }
}
</script>

<style lang="scss">
.odd-map {
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;

  &__router {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &__layers {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  #odd-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }
}
</style>
