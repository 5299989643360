<template>
  <div :class="[`sc-city-title ${$mq}`]">
    <r-icon
      :name="iconName"
      :size="24"
    />
    <r-title
      type="title-1"
      color-type="secondary"
    >
      {{ $t(city) }}
    </r-title>
  </div>
</template>

<script>
const cityList = ['svobodnyy']

export default {
  props: {
    city: {
      type: String,
      validator: val => cityList.indexOf(val) !== -1,
      default: 'svobodnyy'
    }
  },
  computed: {
    iconName() {
      switch (this.city) {
        default:
          return 's-center'
      }
    }
  }
}
</script>

<style lang="scss">
.sc-city-title {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast) !important;
  padding-bottom: 8px;
  > div {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "svobodnyy": "АГПЗ, АГХК"
  },
  "en": {
    "svobodnyy": "АГПЗ, АГХК"
  }
}
</i18n>
