<template>
  <iframecomp iframe-url="https://dmtlp.zone-it.studio/grafana/public-dashboards/6554f3c8614741ada85daeb77533108b" />
</template>

<script>
import iframecomp from '@/components/iframecomp/iframecomp'

export default {
  components: { iframecomp }
}
</script>
