<template>
  <div class="pot-1-print-sheet-header">
    <r-text
      :size="16"
      :weight="700"
    >
      Инструкция для водителей, доставляющих грузы на АГПЗ
    </r-text>
    <img
      src="@/assets/images/logos/nipigas-logo.png"
      alt="NIPIGAS logo"
    >
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.pot-1-print-sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
