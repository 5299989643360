<template>
  <div class="mt-geozones">
    <el-popover
      v-model="popoverVisible"
      placement="bottom"
      width="408"
      trigger="click"
    >
      <r-button
        slot="reference"
        icon="list"
        uppercase
        dropdown
        bold
      >
        Выбрать геозоны для ТС
      </r-button>
      <div class="mt-geozones__select-geozones select-geozones">
        <r-block no-padding>
          <r-block
            no-padding
            col
            space-between
          >
            <r-title type="subtitle-2">
              Геозоны
            </r-title>
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAll"
            >
              Выбрать все
            </el-checkbox>
          </r-block>
          <r-search-input
            placeholder="Поиск по названию"
            :filter-text="filterText"
            @change="filterText = $event"
          />
        </r-block>
        <div class="select-geozones-content">
          <el-checkbox-group
            v-model="checkedGeozones"
            class="select-geozones__group"
            @change="handleGeozones"
          >
            <el-checkbox
              v-for="geozone in initialList"
              :key="geozone.id"
              class="select-geozones__item"
              :label="geozone.id"
            >
              <r-text>
                {{ geozone.name || '-' }}
              </r-text>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-pagination
          v-if="hasPagination"
          class="r-pagination"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalObjects"
          @current-change="currentPage = $event"
        />
        <r-button
          type="primary"
          @click="applyGeozones"
        >
          Применить
        </r-button>
      </div>
    </el-popover>
    <r-text
      v-if="!tableData.length"
      type="caption"
    >
      Нет связанных геозон
    </r-text>
    <r-table-list
      v-else
      :data="tableData"
      :fields-names="fieldsExplanation"
    />
  </div>
</template>

<script>
export default {
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fieldsExplanation: {
        // 'action-zone-visibility': 'Действия',
        'vehicle-access-geozones': 'Геозоны доступа ТС'
      },
      checkedGeozones: [],
      isIndeterminate: false,
      checkAll: false,
      allGeozones: [],
      vehicleGeozones: [],
      vehicleGeozonesIds: [],
      popoverVisible: false,
      filterText: '',
      currentPage: 1,
      pageSize: 25
    }
  },
  computed: {
    tableData() {
      return this.preparedData?.map(g => ({
        'vehicle-access-geozones': g.name || '-'
      }))
    },
    preparedData() {
      return this.allGeozones.filter(g =>
        this.vehicleGeozonesIds.includes(g.id)
      )
    },
    filteredGeozones() {
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) return this.allGeozones

      return this.allGeozones?.filter(g => g.name?.toLowerCase()?.includes(query))
    },
    totalObjects() {
      return this.filteredGeozones?.length || 0
    },
    initialList() {
      if (!this.hasPagination) {
        return this.filteredGeozones
      } else {
        const start =
          this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.pageSize
        const end =
          this.currentPage === 1
            ? this.totalObject < this.pageSize
              ? this.totalObject
              : this.currentPage * this.pageSize
            : this.currentPage * this.pageSize > this.totalObject
              ? this.totalObject
              : this.currentPage * this.pageSize

        return this.filteredGeozones?.slice(start, end) || []
      }
    },
    hasPagination() {
      return this.allGeozones.length > 25
    }
  },
  watch: {
    id() {
      this.getVehicleGeozones()
    }
  },
  async mounted() {
    await this.loadAllGeozones()
    await this.getVehicleGeozones()
  },
  methods: {
    async loadAllGeozones() {
      const request = await this.$store.dispatch('POST_REQUEST', {
        url: 'objectInfoBodyConfig/bfbc88aa-6085-4a9e-9be2-c288c579c494',
        data: {
          config: { only: ['id', 'name', 'agpz', 'aghk'] }
        }
      })
      this.allGeozones = Object.values(request.data) || []
    },
    async getVehicleGeozones() {
      const config = {
        where: [{ field: 'id', value: this.id, op: '=' }],
        include: {
          vehicle_geozones: {}
        }
      }
      const request = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/${this.source_id}?config=${JSON.stringify(config)}`
      })
      this.vehicleGeozones = Object.values(request.data)[0].vehicle_geozones
      this.vehicleGeozonesIds = this.vehicleGeozones.map(g => g.geozone_id)
      this.checkedGeozones = this.preparedData.map(g => g.id)
    },
    buildQuery() {
      const assemblyData = this.allGeozones.map(g => ({
        vehicle_id: this.id,
        geozone_id: g.id,
        id: this.vehicleGeozones.find(vg => vg.geozone_id === g.id)?.id,
        name: g.name
      }))
      const remove = assemblyData
        .filter(g => !this.checkedGeozones.includes(g.geozone_id) && g.id)
        .map(g => ({
          vehicle_id: g.vehicle_id,
          geozone_id: g.geozone_id,
          id: g.id,
          disabled: true
        }))
      const add = assemblyData
        .filter(
          g =>
            this.checkedGeozones.includes(g.geozone_id) &&
            !this.vehicleGeozonesIds.includes(g.geozone_id)
        )
        .map(g => ({
          vehicle_id: g.vehicle_id,
          geozone_id: g.geozone_id
        }))
      return [...add, ...remove]
    },
    async applyGeozones() {
      await this.setGeozones(this.buildQuery())
      this.getVehicleGeozones()
      this.popoverVisible = false
    },
    async setGeozones(vehicle_geozones) {
      await this.$store.dispatch('POST_REQUEST', {
        url: `objectInfo/${this.source_id}`,
        data: {
          id: this.id,
          vehicle_geozones
        }
      })
    },
    handleGeozones(v) {
      this.checkedGeozones = v
      const checkedCount = this.checkedGeozones.length
      this.checkAll = checkedCount === this.allGeozones.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.allGeozones.length
    },
    handleCheckAll(v) {
      this.checkedGeozones = v ? this.allGeozones.map(i => i.id) : []
      this.isIndeterminate = false
    }
  }
}
</script>

<style lang='scss'>
.select-geozones {
  display: grid;
  grid-gap: 0.5rem;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    max-height: 60vh;
    min-height: 370px;
    overflow: auto;
    display: grid;
    grid-gap: 0.5rem;
    align-items: start;
  }

  .el-checkbox-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    grid-gap: 0.5rem;

    & > label {
      display: flex;
      align-items: center;
    }
  }
}

.mt-geozones {
  width: 100%;
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-gap: 0.5rem;
  grid-auto-flow: row;
  align-content: start;

  .el-table {
    &::before {
      height: 0;
    }

    .r-button {
      padding: 0 !important;
      background-color: transparent !important;

      & > span {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr auto;
        justify-items: start;
      }
    }
  }
}
</style>
<i18n>
{
  "ru": {
    "action-zone-visibility": "Статус",
    "vehicle-access-geozones": "Геозоны доступа ТС"
  }
}

</i18n>
