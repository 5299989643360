<template>
  <div class="iframe">
    <iframe class=iframe title="IFrame" :src="iframeUrl" />
  </div>
</template>

<script>
export default {
  props: {
    iframeUrl: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.iframe {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
