<template>
  <div
    v-loading="isLoading"
    class="rp-attr-filter-content-tabs"
  >
    <div class="rp-attr-filter-content-tabs-control">
      <div class="rp-attr-filter-content-tabs-control-wrapper">
        <div
          v-for="tab in tabsList"
          :key="tab.id"
          :class="['rp-attr-filter-content-tabs-control__item', { active: activeTab === tab.value}]"
          @click="changeTab(tab.value)"
        >
          <r-text>{{ tab.title }}</r-text>
        </div>
      </div>
      <rp-attr-filter-content-note />
    </div>
    <div class="rp-attr-filter-content-tabs-content">
      <rp-attr-filter-content-table
        v-if="!isLoading"
        :data="tableData"
        :fields="fields"
        :all-checked="activeTab === 'included'"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import rpAttrFilterContentNote from './rp-attr-filter-content-note'
import rpAttrFilterContentTable from './rp-attr-filter-content-table'

import { tabsList } from './configs/'
import { getFields, getConfig, parseData } from './helpers'

export default {
  components: {
    rpAttrFilterContentNote,
    rpAttrFilterContentTable
  },
  data() {
    return {
      isLoading: false,
      activeTab: 'included',
      tableData: [],
      fields: [],
      tabsList
    }
  },
  computed: {
    updateAttrFilter() {
      return this.$store.state.rolePermission.updateAttrFilter || false
    },
    roleAttrFilterId() {
      return this.$store.state.rolePermission.roleAttrFilterId || null
    },
    roleAttrFilter() {
      return this.$store.state.rolePermission.roleAttrFilter || null
    },
    roleAttrFilterExcluded() {
      return this.$store.state.rolePermission.roleAttrFilterExcluded || null
    },
    roleAttrFilterItemsConfig() {
      return this.$store.state.rolePermission.roleAttrFilterItemsConfig || null
    }
  },
  watch: {
    activeTab() {
      this.getDatasource()
    },
    updateAttrFilter: {
      handler: function(val) {
        if (!val) return
        this.getDatasource()
        this.$store.state.rolePermission.updateAttrFilter = false
      },
      deep: true
    }
  },
  created() {
    this.resetConfigs()
    this.getDatasource()
  },
  methods: {
    resetConfigs() {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterActiveTab',
        value: this.activeTab
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterExcludedItems',
        value: null
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterItems',
        value: null
      })
    },
    changeTab(tab) {
      if (this.activeTab === tab) return
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterActiveTab',
        value: tab
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterExcludedItems',
        value: null
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterItems',
        value: null
      })

      this.activeTab = tab
    },
    async getDatasource() {
      this.isLoading = true

      try {
        const fields = await getFields(this, this.roleAttrFilterId)

        this.fields = fields.filter(f => f.title !== 'id').sort((a, b) => {
          if (b.title === 'reg_number') return 1
          if (b.title === 'packing_list_no') return 1
          if (a.title === 'agpz' || a.title === 'aghk') return 1
          if (a.title !== 'agpz' || a.title !== 'aghk') return -1
          return 0
        })
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterFields',
          value: this.fields
        })
        const isIncluded = this.activeTab === 'included'
        const filter = isIncluded ? this.roleAttrFilter : this.roleAttrFilterExcluded
        const config = getConfig(
          fields,
          filter,
          isIncluded,
          this.roleAttrFilterItemsConfig,
          this.roleAttrFilterId
        )
        const url = `objectInfoBodyConfig/${this.roleAttrFilterId}`
        const { data } = await this.$store.dispatch('POST_REQUEST', { url, data: { config: config } })

        this.tableData = parseData(data, this.roleAttrFilterId)
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterInitialData',
          value: cloneDeep(this.tableData)
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang='scss'>
.rp-attr-filter-content-tabs {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-gap: 0.5rem;
  align-content: flex-start;
  height: 100%;

  .table-prime-simple .p-datatable-scrollable-wrapper {
    max-height: 100%;
    overflow: auto;
  }

  &-control {
    display: flex;

    &-wrapper {
      display: flex;
      width: 100%;
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      box-sizing: border-box;
      padding: 8px;
      background-color: var(--bg_containers);

      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }

      &:last-child {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }

      &:hover {
        background-color: var(--accent_hover);
      }

      &.active {
        background-color: var(--accent_active);
        cursor: initial;
      }
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
</style>
