import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { getMapConfigs } from '@/utils'

export const initMap = async component => {
  const { baselayer } = getMapConfigs(component, 'journal')

  mapboxgl.accessToken = mapToken

  const center = { lat: 51.53, lng: 128.183 }
  const zoom = 13

  component.mapgl = new mapboxgl.Map({
    container: 'journal-map',
    preserveDrawingBuffer: true,
    style: baselayer,
    center,
    zoom
  })

  component.mapgl.dragRotate.disable()

  component.mapgl.on('load', async() => {
    component.mapgl.resize()
  })
}
