export const moduleLinks = {
  default: [
    {
      id: 1,
      value: 'gis',
      name: 'GIS',
      icon: 'ksodd',
      routesLinks: [
        {
          id: 1,
          value: 'map',
          to: '/app/map',
          name: 'Map',
          icon: 'ksodd-map'
        },
        {
          id: 2,
          value: 'book',
          to: '/app/book',
          name: 'Book',
          icon: 'ksodd-tables'
        },
        {
          id: 3,
          value: 'odd',
          to: '/app/odd',
          name: 'ODD',
          icon: 'bus-hollow'
        }
      ]
    },
    {
      id: 2,
      value: 'monitoring',
      name: 'Monitoring',
      icon: 'bus-hollow',
      routesLinks: [
        {
          id: 1,
          value: 'monitoring_ts',
          to: '/app/monitoring_ts',
          name: 'Internal Monitoring',
          icon: 'car-hollow'
        },
        {
          id: 2,
          value: 'railway-control',
          to: '/app/railway-control',
          name: 'Railway Dispatcher',
          icon: 'train-hollow'
        },
        {
          id: 3,
          value: 'public-transport',
          to: '/app/public-transport',
          name: 'PT Registry',
          icon: 'bus-hollow'
        },
        {
          id: 4,
          value: 'monitoring-tmc',
          to: '/app/monitoring-tmc',
          name: 'External Monitoring',
          icon: 'truck-hollow'
        }
      ]
    },
    {
      id: 3,
      value: 'analytics_reports',
      name: 'Analytics',
      icon: 'ksodd-graphs',
      routesLinks: [
        {
          id: 1,
          value: 'analytics',
          to: '/app/analytics',
          name: 'Analytics',
          icon: 'ksodd-graphs'
        },
        {
          id: 2,
          value: 'kpp_dashboard',
          name: 'KppDashboard',
          icon: 'ksodd-graphs'
        }
        // {
        //   id: 2,
        //   value: 'reports',
        //   to: '/app/reports',
        //   name: 'Reports',
        //   icon: 'ksodd-graphs'
        // }
      ]
    },
    {
      id: 4,
      value: 'modelling',
      name: 'Modelling',
      icon: 'modelling',
      routesLinks: [
        {
          id: 0,
          value: 'modelling',
          to: '/app/modelling',
          name: 'Modelling',
          icon: 'modelling'
        }
      ]
    },
    {
      id: 5,
      value: 'situation-center',
      name: 'Situation Center Display',
      icon: 's-center',
      routesLinks: [
        {
          id: 1,
          value: 'dashboard',
          to: '/app/dashboard',
          name: 'Situation Center Display',
          icon: 's-center'
        },
        {
          id: 2,
          value: 'journal',
          to: '/app/journal',
          name: 'Journal',
          icon: 'open-table'
        }
      ]
    },
    {
      id: 6,
      value: 'bdd',
      name: 'Bdd',
      icon: 'bdd',
      routesLinks: [
        {
          id: 1,
          value: 'checklist',
          to: '/bdd-checklists',
          name: 'Vehicle Inspection Sheets',
          icon: 'bdd'
        },
        {
          id: 2,
          value: 'bdd-applications',
          to: '/app/bdd/applications',
          name: 'Vehicle Inspection Applications',
          icon: 'bdd'
        },
        {
          id: 3,
          value: 'bdd-restrictions-register',
          to: '/app/bdd/restrictions-register',
          name: 'Register of closed areas',
          icon: 'closed'
        },
        {
          id: 4,
          value: 'bdd-preventive-measures',
          to: '/app/bdd/preventive-measures',
          name: 'Register of preventive measures',
          icon: 'work'
        },
        {
          id: 5,
          value: 'bdd-pass-register',
          to: '/app/bdd/pass-register',
          name: 'Register of passes',
          icon: 'kpp-gate'
        },
        {
          id: 6,
          value: 'bdd-violations-register',
          to: '/app/bdd/violations-register',
          name: 'Register of violations',
          icon: 'bdd'
        },
        {
          id: 'bdd-acts',
          value: 'bdd-acts',
          to: '/app/bdd/acts',
          name: 'Acts register',
          icon: 'bdd'
        }
      ]
    },
    {
      id: 7,
      value: 'pot-1',
      name: 'Pot-1',
      icon: 'pot-1',
      routesLinks: [
        {
          id: 1,
          value: 'pot-1-dispatcher',
          to: '/app/pot-1-dispatcher',
          name: 'POT-1 Dispatcher',
          icon: 'pot-1'
        },
        {
          id: 2,
          value: 'pot-1-logistic',
          to: '/app/pot-1-logistic',
          name: 'POT-1 Warehouse',
          icon: 'pot-1'
        },
        {
          id: 3,
          value: 'pot-1-terminal',
          to: '/pot-1-terminal',
          name: 'POT-1 Terminal',
          icon: 'pot-1'
        },
        {
          id: 'unload',
          value: 'unload',
          to: '/app/unload',
          name: 'unload',
          icon: 'storehouse'
        }
      ]
    }
  ]
}

export const actionLinks = {
  default: [
    {
      id: 1,
      value: 'public-notifications',
      name: 'PAT notifications',
      icon: 'public-notification'
    },
    {
      id: 2,
      value: 'email-distributions',
      name: 'Mail Sender',
      icon: 'email-notification',
      divider: true
    },
    {
      id: 3,
      value: 'user-roles',
      name: 'UserRoles',
      icon: 'users',
      to: '/app/role-permission'
    },
    {
      id: 'calendar',
      value: 'calendar',
      name: 'Calendar',
      icon: 'date-calendar',
      to: '/app/calendar'
    },
    {
      id: 4,
      value: 'ds-manager',
      name: 'DataSource Manager',
      icon: 'database'
    }
  ]
}

export const accountActionList = {
  default: [
    {
      value: 'title',
      name: 'account',
      devided: false
    },
    {
      value: 'about',
      name: 'about',
      devided: false
    },
    {
      value: 'theme',
      name: 'theme',
      devided: true
    },
    {
      value: 'settings-reset',
      name: 'settings-reset',
      devided: false
    }
  ]
}
