import baselayers from '../config/baselayers'
import mapboxStyles from '@/constants/mapbox_styles'

export class MapConfigEvents {
  constructor(map, module) {
    this.$store = map.$store
    this.mapgl = map.mapgl
    this.module = module
  }

  addEventHandler() {
    if (!this.module) return

    this.mapgl.on('moveend', () => {
      const center = this.mapgl.getCenter()
      const zoom = this.mapgl.getZoom()

      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'configs',
        module: this.module,
        value: {
          center,
          zoom
        }
      })
    })
  }
}

export const getMapConfigs = (state, module, defCenter = false) => {
  const defaultCenter = defCenter || [128.11, 51.46]
  const configs = state.$store.state.mapConfigs?.configs[module] || {
    center: defaultCenter,
    zoom: 10
  }
  const zoom = configs.zoom
  const center = configs.center
  const baselayerId = state.$store.state.mapConfigs?.baselayers[module] || 5
  const baselayer = getBaselayerStyle(baselayerId)

  return { center, zoom, baselayer }
}

const getBaselayerStyle = function(id) {
  const baselayerName = baselayers.find(e => e.id === id)?.name

  return mapboxStyles[baselayerName]
}
