export const fields = [
  // main
  {
    title: 'АГХК',
    type: 'checkbox',
    model: 'aghk',
    column: true,
    width: 'half',
    tab: 'main'
  },
  {
    title: 'АГПЗ',
    type: 'checkbox',
    model: 'agpz',
    column: true,
    width: 'half',
    tab: 'main'
  },
  {
    title: 'Подпроект',
    type: 'select',
    filterable: true,
    select: 'subproject',
    model: 'subproject_id',
    width: 'half',
    tab: 'main'
  },
  {
    title: 'Дата и время фиксации',
    type: 'datetime',
    model: 'datetime',
    width: 'half',
    tab: 'main'
  },
  {
    title: 'Место',
    type: 'text',
    model: 'address',
    tab: 'main'
  },
  {
    title: 'Сотрудник, выявивший нарушение (ФИО, должность)',
    type: 'select',
    filterable: true,
    select: 'employees',
    model: 'detection_employee_id',
    tab: 'main'
  },
  {
    title: 'Подрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'contractor_id',
    tab: 'main'
  },
  {
    title: 'Субподрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'subcontractor_id',
    tab: 'main'
  },
  {
    title: 'Субсубподрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'subsubcontractor_id',
    tab: 'main'
  },
  {
    title: 'Описание нарушения',
    type: 'textarea',
    rows: 5,
    model: 'description',
    tab: 'main'
  },
  {
    title: 'Требования нормативного документа',
    type: 'textarea',
    model: 'norm_doc_requirements',
    tab: 'main'
  },
  {
    title: 'Нормативная документация',
    type: 'textarea',
    model: 'normative_doc',
    tab: 'main'
  },
  {
    title: 'Корректирующие мероприятия',
    type: 'textarea',
    model: 'corrective_actions',
    tab: 'main'
  },
  {
    title: 'Срок устранения нарушений',
    type: 'datetime',
    model: 'elimination_date',
    format: 'dd.MM.yyyy HH:mm',
    tab: 'main'
  },
  {
    title: 'Фактическая дата устранения нарушений',
    type: 'datetime',
    model: 'elimination_date_fact',
    format: 'dd.MM.yyyy HH:mm',
    tab: 'main'
  },
  {
    title: 'Не категорируется',
    type: 'checkbox',
    column: true,
    model: 'not_categorized',
    width: 'half',
    tab: 'main'
  },
  {
    title: 'Не относится к проекту',
    type: 'checkbox',
    column: true,
    model: 'not_project',
    width: 'half',
    tab: 'main'
  },
  // violator
  {
    title: 'ФИО',
    type: 'text',
    model: 'guilty_name',
    tab: 'violator'
  },
  {
    title: 'Дата рождения',
    type: 'date',
    model: 'guilty_birthday',
    format: 'dd.MM.yyyy',
    tab: 'violator'
  },
  {
    title: 'Должность',
    type: 'text',
    model: 'guilty_position',
    tab: 'violator'
  },
  {
    title: 'Организация',
    type: 'select',
    select: 'contractor',
    filterable: true,
    model: 'guilty_organization_id',
    tab: 'violator'
  },
  {
    title: 'Номер личного пропуска',
    type: 'text',
    model: 'guilty_personal_pass',
    width: 'half',
    tab: 'violator'
  },
  {
    title: 'Категория нарушения',
    type: 'select',
    select: 'accident_category_custom',
    model: 'guilty_accident_category',
    width: 'half',
    tab: 'violator'
  },
  {
    title: 'Корректирующие мероприятия',
    type: 'textarea',
    model: 'guilty_corrective_actions',
    tab: 'violator'
  },
  {
    title: 'Правило трёх нарушений',
    type: 'select',
    select: 'guilty_three_vio',
    model: 'guilty_three_vio_id',
    width: 'full',
    tab: 'violator'
  },
  {
    title: 'Модель ТС',
    model: 'model',
    type: 'text',
    read_only: true,
    width: 'half',
    tab: 'violator'
  },
  {
    title: 'Гос. номер',
    type: 'select',
    select: 'vehicle',
    label: 'reg_number',
    model: 'vehicle_id',
    filterable: true,
    width: 'half',
    tab: 'violator'
  },
  {
    title: 'Вид ТС',
    model: 'vehicle_type',
    type: 'text',
    read_only: true,
    width: 'half',
    tab: 'violator'
  },
  {
    title: 'Тип ТС',
    model: 'vehicle_type',
    tab: 'violator',
    type: 'select',
    read_only: true,
    width: 'half',
    adjacent: 'vehicle_id',
    select: 'vehicle',
    reflection: {
      related: 'vehicleTypes',
      field: 'vehicle_type_id',
      attr: 'vehicle_type_group'
    }
  },
  {
    title: 'Номер пропуска ТС',
    model: 'vehicle_permit',
    type: 'text',
    read_only: true,
    tab: 'violator'
  },
  {
    title: 'ТС удалено с проекта',
    type: 'checkbox',
    column: true,
    model: 'vehicle_is_removed',
    tab: 'violator'
  }
]

export const titles = {
  edit: 'Редактирование нарушения',
  create: 'Выберите вид нарушения',
  view: 'Карточка нарушения'
}

export const tabs = [
  { title: 'Общая информация', label: 'main' },
  { title: 'Нарушитель', label: 'violator' },
  { title: 'Файлы и комментарии', label: 'filesComments' }
]

export const sources = {
  store: 'bdd',
  field: 'violation',
  updateField: 'updateViolations'
}

export const violationTypeField = [
  {
    title: 'п.п. № нарушения',
    type: 'text',
    read_only: true,
    model: 'no'
  },
  {
    title: 'Вид нарушения',
    type: 'select',
    select: 'type',
    model: 'type_id',
    read_only: true
  }
]
